import { Link } from 'react-router-dom';
import { getFullPrice } from '../../join-commute/helpers/vanHelpers';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';
import { useEffect, useState } from 'react';
import dateUtility from '../../shared/dateUtility';
import analyticsService from '../../shared/analytics/analytics-service';
import SponsoredVanpool from '../../join-commute/helpers/sponsoredVanpool';

export default function SubscriptionPlan(props) {
  const { subscription, vanpoolInfo } = props;
  const [startDate, updateStartDate] = useState(null);
  const [endDate, updateEndDate] = useState(null);
  const [plan, updatePlan] = useState('');
  const [priceObj, updatePriceObj] = useState({});

  useEffect(() => {
    if (props.startDate) {
      const startDay = dateUtility.parseDateAsMonthDayYearFromGMT(props.startDate);
      updateStartDate(startDay);
    }
    if (props.endDate) {
      const endDay = dateUtility.parseDateAsMonthDayYearFromGMT(props.endDate);
      updateEndDate(endDay);
    }
    let planName = '';
    if (subscription) {
      if (!subscription.package) {
        if (props.subscriptionAPIInfo && props.subscriptionAPIInfo.package) {
          planName = props.subscriptionAPIInfo.package.replace('X', '');
        } else {
          if (props.subscriptionAPIInfo && props.subscriptionAPIInfo.pendingChange) {
            planName = props.subscriptionAPIInfo.pendingChange.package.replace('X', '');
          }
        }
      } else {
        planName = subscription.package.replace('X', '');
      }
      updatePlan(planName);
    }
    let price = {};
    if (props.subscriptionAPIInfo && props.subscriptionAPIInfo.package && props.subscriptionAPIInfo.totalAmount) {
      price = getFullPrice(props.subscriptionAPIInfo.totalAmount);
    } else if (props.subscriptionAPIInfo && props.subscriptionAPIInfo.pendingChange && props.subscriptionAPIInfo.pendingChange.totalAmount) {
      price = getFullPrice(props.subscriptionAPIInfo.pendingChange.totalAmount);
    }
    updatePriceObj(price);
  }, [props, props.subscriptionAPIInfo]);

  function clickManage() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "manage"
      }
    });
  }

  return (
    <div className='subscriptionPlan' role='region' aria-labelledby='subscription-title'>
      <div className='planDetails'>
        <div className='heading'>
          <div>
          <h2 id='subscription-title' className='sub-title'>Subscription Plan</h2>
            {subscription && (subscription.package === 'Unlimited' ?
              <div className='plan'>Unlimited Plan</div> :
              <div className='plan'>{plan}-Day Plan</div>)
            }
          </div>
          {props.isVanStartingInFuture === true ?
            <div className='starting-date' aria-live="polite">Starting {startDate}</div>
            :
            props.isVanCancelled === true ?
              <div className='ending-date' aria-live="polite">Ending {endDate}</div>
              :
              (props.viewLink ? <Link className="view-link green-link-underline" to="/payment/summary/subscription" aria-label="View plan details">
                view plan
              </Link> :
                <button
                  className='manage-button'
                  onClick={clickManage}
                  aria-label="Manage subscription"
                >
                  <Link to="/myprofile">Manage</Link>
                </button>)}
        </div>
        <div className='right-container'>
          <div className='rate' aria-labelledby='subscription-price'>
            <div className='price-group' role='group' id='subscription-price' aria-label={`${priceObj.price} dollars and ${priceObj.decimal} cents total per month`}>
              <div className='doller' aria-hidden='true'>{'$'}</div>
              <div className='price'>{numberWithCommas(priceObj.price)}</div>
              <div className="decimal">.{priceObj.decimal}</div>
            </div>
            <div className='perMonth' aria-label='total per month'>total per month</div>
            {props.subscriptionAPIInfo?.vanpool.isSubsidized && <SponsoredVanpool />}
          </div>
        </div>
      </div>
      <div className='vl' aria-hidden='true'></div>
      <div className='vanpoolDetails' role='group' aria-labelledby='vanpool-info'>
        <div className='vanpoolName'>
          <h3 id='vanpool-info' className='name'>
            {vanpoolInfo.vanpoolName}
          </h3>
          <div className='type'>
            {vanpoolInfo.vehicleType}
          </div>
        </div>
        {<div className='vanpoolId'>
          <div className='key'>
            Vanpool ID
          </div>
          <div className='id'>
            {vanpoolInfo.vanpoolId}
          </div>
        </div>}
      </div>
    </div>
  )
}